body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'Guardians';
  src: local('Guardians'), url(/static/media/Guardians.e89ef6c6.ttf) format('truetype');
}
/*@import "Desktop.css" only screen and (min-width: 1200px);*/

/*!*@media screen and (min-width: 768px) and (max-width: 1765px) {*!*/
/*!*}*!*/
/*!*@media screen and (max-width: 600px) {*!*/
/*!*  .navbar a:not(:first-child) {display: none;}*!*/
/*!*}*!*/
@media only screen and (min-width: 1200px) {

.navbar-container {
    margin-top: 4.625rem;
}

div hr.navbarline {
    position: relative;
    width: 74%;
    margin-left: 29.5625rem;
    border-top: 0.0625rem solid var(--diviBorder);
    opacity: 0.5;
}

nav {
    color: var(--diviGrey);
    font: normal normal 700 1.35rem/1.63rem Lato;
    letter-spacing: 0.075rem;
    position: relative;
    margin-left: 75.5rem;
    text-align: left;
}

.navbarLink {
    border: none;
    padding: 0.625rem 1.84375rem 0.5625rem 1.84375rem;
    color: var(--diviGrey);
}

.active {
    border-top: 0.0625rem solid rgba(112, 112, 112, 0.5);;
    border-right: 0.0625rem solid rgba(112, 112, 112, 0.5);
    border-left: 0.0625rem solid rgba(112, 112, 112, 0.5);
}

a {
    text-decoration: none;
}

Nav a:hover {
    color: var(--diviBlue);
}


div.sidebar-container {
    position: fixed;
    z-index: 1;
    width: 23.03125rem;
    height: 100%;
    margin-top: -4.6rem;
    overflow: hidden;
}


.sidebar {
    position: fixed;
    font: normal normal 500 1.125rem/0.625rem Guardians;
    text-align: left;
    color: var(--diviBlue);
    border: var(--diviSky);
    margin-top: 3.07rem;
    left: 5rem;
}

.profileDescription {
    font: normal normal 600 1.5rem/1.8125rem Lato;
    margin-left: -1rem;
    letter-spacing: 0px;
    color: var(--diviGrey);
    opacity: 1;
}

div.vertical {
    position: fixed;
    margin-top: -2rem;
    left: 25.0625rem;
    width: 0.0625rem;
    height: 90%;
    background: rgba(112, 112, 112, 0.5);
}

.sidebar hr {
    position: relative;
    width: 19.3125rem;
    margin-left: -1.875rem;
    opacity: 0.50;
}

.sidebar img {
    margin-top: 1.6875rem;
    top: 9rem;
    left: 3.625rem;
    width: 16rem;
    height: 14.9375rem;
}

#headerDivetus {
    color: var(--diviBlue);
    border: none;
}

.faIcons {
    margin-left: 1.29375rem;
    font-size: 3.09375rem;
}

.faIcons:hover {
    color: var(--diviDark);
}

.sidebarIcons {
    margin-left: -1rem;
}

.customTooltip {
    font-family: Lato !important;
}

.commissionStatusText {
    margin-left: 0.6rem;
    margin-top: 1.5rem;
    color: var(--diviGrey);
    text-align: left;
    font: normal normal normal 1.125rem/1.35rem Lato;
    letter-spacing: 1px;
    opacity: 1;
}

.commissionStatusState {
    font: normal normal bold 1.125rem/1.35rem Lato;
    color: var(--diviGreen);
}

.buttonStyle {
    background: var(--diviBlue) 0% 0% no-repeat padding-box;
    border: none;
    border-radius: 1.25rem;
    opacity: 1;
    margin-left: -1.7rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
    width: 19.25rem;
    height: 4.375rem;
    text-align: center;
    font: normal normal 600 1.35rem/1.63125rem Lato;
    letter-spacing: 0.45rem;
    color: var(--diviWhite);
    outline: none;
}

.buttonStyle:hover {
    background: var(--diviDark);
}

.carousel.carousel-slider .control-arrow {
    opacity: 1 !important;
}

.carousel .thumb.selected  {
    border: 1px solid var(--diviBlue) !important;
}

.thumb:hover {
    border: 1px solid var(--diviBlue) !important;
}

.carousel .thumb.selected {
    border: 1px solid var(--diviBlue) !important;
}

.carousel .thumb {
    margin-left: 30px !important;
}
}
@media only screen and (min-width: 1200px) {
.bodyContent {
    margin-left: 29.625em;
}

body {
    overflow-x: hidden;
}

.home {
    margin-top: 5.96875rem;
}

.home p.homeContent {
    color: var(--diviSilver);
    font: normal normal 500 1.35rem/1.85625rem Open Sans;
    max-width: 47.9375rem;
    max-height: 26.1875rem;
    text-align: left;
    display: block;
    opacity: 1;

}

.home p.homeContentRed {
    max-width: 47.9375rem;
    max-height: 9.0625rem;
    color: var(--diviRed);
    text-align: left;
    font: normal normal 800 1.35rem/1.63125rem Lato;
    letter-spacing: 0.075rem;
    display: block;
}

.home .homeTOS {
    color: var(--diviRed);
}

.tos {
    margin-top: 2.78125rem;
}

.dragon {
    position: absolute;
    top: 484px;
    left: 1240px;
    width: 630px;
    height: 446px;
}
}
@media only screen and (max-width: 1199px) {

div hr.navbarline {
   display: none;
}

nav {
    color: var(--diviGrey);
    font: normal normal 700 1.35rem/1.63rem Lato;
    letter-spacing: 0.075rem;
    position: relative;
    display: flex;
    align-content: center;
    left: 3rem;
    top: -8rem;
    width: 100%;
    text-align: left;
}

.navbarLink {
    border-bottom: 1px solid var(--diviGrey);
    padding: 0.625rem 1.84375rem 0.5625rem 5rem;
    color: var(--diviGrey);
}

.active {
}

a {
    text-decoration: none;
}

.bodyContent {
    margin-top: 2rem !important;
}

Nav a:hover {
    color: var(--diviBlue);
}


/*div.sidebar-container {*/
/*    position: relative !important;*/
/*    margin-top: 2rem;*/
/*}*/


.sidebar {
    display: flex;
    font: normal normal 500 1.125rem/0.625rem Guardians;
    position: relative;
    margin-top: 5rem !important;
    color: var(--diviBlue);
    border: var(--diviSky);
    transform-origin: 50rem 25rem !important;
}

.profileDescription {
    font: normal normal 600 1.5rem/1.8125rem Lato;
    margin-left: -1rem;
    letter-spacing: 0px;
    color: var(--diviGrey);
    opacity: 1;
}

div.vertical {
   display: none;
}

.sidebar hr {
    display: none
}

.sidebar img {
    display: none;
}

.profileDescription {
    display: none;
}
#headerDivetus {
    color: var(--diviBlue);
    margin-right: 1rem;
    margin-left: 2rem;
    position: relative;

}

.faIcons {
    display: inline-flex;
    margin-right: 1rem;
    font-size: 3.09375rem;
}

.faIcons:hover {
    color: var(--diviDark);
}

.sidebarIcons {
    display: flex;
}

.tos {
    margin-left: 3rem !important;
}

.galleryOverride {
    margin-left: -28rem !important;
    margin-top: 2rem !important;
}

.twitchHeading {
    width: 700px !important;
}

.bodyContent.home {
    margin-left: 2rem !important;
    color: var(--diviSilver);
    font: normal normal 500 1.35rem/1.85625rem Open Sans;
    padding: 0 2rem 2rem 2rem;
    align: center;
}

.bodyContent img {
    margin-left: 12rem !important;
    margin-top: 10rem !important;
    transform: scale(2) !important;
}
.customTooltip {
    font-family: Lato !important;
}

.Unavailable {
    margin-left: 12rem !important;
    margin-top: 15rem !important;
}

.UnavailableOther {
    margin-left: 40rem !important;
    margin-top: 5rem !important;
}
.commissionStatusText {
    margin-left: 0.6rem;
    margin-top: 1.5rem;
    color: var(--diviGrey);
    text-align: left;
    font: normal normal normal 1.125rem/1.35rem Lato;
    letter-spacing: 1px;
    opacity: 1;
}

.commissionStatusState {
    font: normal normal bold 1.125rem/1.35rem Lato;
    color: var(--diviGreen);
}

.buttonStyle {
    display: none;
    /*background: var(--diviBlue) 0% 0% no-repeat padding-box;*/
    /*border: none;*/
    /*border-radius: 1.25rem;*/
    /*opacity: 1;*/
    /*margin-left: -1.7rem;*/
    /*margin-top: 1rem;*/
    /*margin-bottom: 1rem;*/
    /*width: 19.25rem;*/
    /*height: 4.375rem;*/
    /*text-align: center;*/
    /*font: normal normal 600 1.35rem/1.63125rem Lato;*/
    /*letter-spacing: 0.45rem;*/
    /*color: var(--diviWhite);*/
    /*outline: none;*/
}

.buttonStyle:hover {
    background: var(--diviDark);
}

.carousel.carousel-slider .control-arrow {
    opacity: 1 !important;
}

.carousel .thumb.selected  {
    border: 1px solid var(--diviBlue) !important;
}

.thumb:hover {
    border: 1px solid var(--diviBlue) !important;
}

.carousel .thumb.selected {
    border: 1px solid var(--diviBlue) !important;
}

.carousel .thumb {
    margin-left: 30px !important;
}
}
/*only screen and (min-width: 1200px)*/

:root {

    /* Colors: */
    --diviWhite: #FFFFFF;
    --diviGrey: #6D6A6A;
    --diviSilver: #8B8989;
    --diviRed: #F81212;
    --diviBlue: #72A9C5;
    --diviDark: #36444B;
    --diviSky: #E5F6FF;
    --diviGreen: #83DE71;
    --diviBorder: #707070;
}

.wrapper {
    width: 100%;
}

/*@media screen and (min-resolution: 105.6dpi)*/
/*{*/
/*    .zoomWrapper {*/
/*        transform: scale(0.9);*/
/*        transform-origin: -3rem 9rem;*/
/*        width: 125%;*/
/*        height: 125%;*/
/*    }*/

/*    .sidebar-container {*/
/*        transform: scale(0.9);*/
/*        transform-origin: top left;*/
/*        width: 125%;*/
/*        height: 125%;*/
/*    }*/

/*    .sidebar {*/
/*        margin-top: 5rem;*/
/*        transform: scale(0.9);*/
/*        transform-origin: top left;*/
/*        height: 125%;*/
/*    }*/

/*    div.vertical {*/
/*        transform: scale(0.9);*/
/*        transform-origin: -30em -5em;*/
/*        height: 100%;*/
/*    }*/
/*}*/

@media screen and (-webkit-min-device-pixel-ratio: 1.25), screen and (min-resolution: 120dpi), screen and (-webkit-min-device-pixel-ratio: 1.0999999999999999), screen and (min-resolution: 105.6dpi), screen and (-webkit-min-device-pixel-ratio: 1.5), screen and (min-resolution: 144dpi), screen and (-webkit-min-device-pixel-ratio: 1.75), screen and (min-resolution: 168dpi), screen and (-webkit-min-device-pixel-ratio: 2), screen and (min-resolution: 192dpi)
{
    .zoomWrapper {
        transform: scale(0.8);
        transform-origin: -5rem 0;
        width: 125%;
        height: 125%;
    }

    .sidebar-container {
        transform: scale(0.8);
        transform-origin: top left;
        width: 125%;
        height: 125%;
    }

    .sidebar {
        margin-top: 5rem;
        transform: scale(0.8);
        transform-origin: top left;
        height: 125%;
    }

    div.vertical {
        transform: scale(0.8);
        transform-origin: -30em -5em;
        height: 115%;
    }

    /*.navbar-container {*/
    /*    transform: scale(0.8);*/
    /*    transform-origin: top left;*/
    /*}*/

    /*div hr.navbarline {*/
    /*    width: 92%;*/
    /*}*/

    /*.bodyContent {*/
    /*    transform: scale(0.8);*/
    /*    transform-origin: -30em -5em;*/
    /*}*/

    /*.dragon {*/
    /*    transform: scale(0.8);*/
    /*    transform-origin: left;*/
    /*    margin: -13em -25em;*/
    /*}*/

    /*.galleryOverride {*/
    /*    transform: scale(0.8);*/
    /*    transform-origin: top left;*/
    /*}*/

    /*div.imageGallery {*/
    /*    margin-left: 4rem !important;*/
    /*}*/
}

/*@media screen and (min-resolution: 144dpi) {*/
/*    .zoomWrapper {*/
/*        transform: scale(0.66);*/
/*        transform-origin: -1rem -5rem;*/
/*        width: 125%;*/
/*        height: 125%;*/
/*    }*/

/*    .sidebar {*/
/*        transform: scale(0.7);*/
/*        transform-origin: -2rem -7rem;*/
/*    }*/

/*    div.vertical {*/
/*        transform: scale(0.77);*/
/*        transform-origin: -35em -5em;*/
/*        height: 115%;*/
/*    }*/

/*}*/
/*@media screen and (min-resolution: 168dpi) {*/
/*    .zoomWrapper {*/
/*        transform: scale(0.56);*/
/*        transform-origin: -1rem -5rem;*/
/*        width: 140%;*/
/*        height: 100%;*/
/*    }*/

/*    .sidebar {*/
/*        transform: scale(0.56);*/
/*        transform-origin: -2rem -5.7rem;*/
/*    }*/

/*    div.vertical {*/
/*        transform: scale(0.7);*/
/*        transform-origin: -40em -5em;*/
/*        height: 130%;*/
/*    }*/

/*}*/


/*@media screen and (min-resolution: 192dpi) {*/
/*    .zoomWrapper {*/
/*        transform: scale(0.50) translateZ(0);*/
/*        transform-origin: 0 -5rem;*/
/*        width: 175%;*/
/*        height: 175%;*/
/*    }*/

/*    .sidebar {*/
/*        transform: scale(0.5);*/
/*        transform-origin: -1rem -5.5rem;*/
/*    }*/

/*    div.vertical {*/
/*        transform: scale(0.8);*/
/*        transform-origin: -60em -5em;*/
/*        height: 115%;*/
/*    }*/

/*}*/
