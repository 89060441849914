
.navbar-container {
    margin-top: 4.625rem;
}

div hr.navbarline {
    position: relative;
    width: 74%;
    margin-left: 29.5625rem;
    border-top: 0.0625rem solid var(--diviBorder);
    opacity: 0.5;
}

nav {
    color: var(--diviGrey);
    font: normal normal 700 1.35rem/1.63rem Lato;
    letter-spacing: 0.075rem;
    position: relative;
    margin-left: 75.5rem;
    text-align: left;
}

.navbarLink {
    border: none;
    padding: 0.625rem 1.84375rem 0.5625rem 1.84375rem;
    color: var(--diviGrey);
}

.active {
    border-top: 0.0625rem solid rgba(112, 112, 112, 0.5);;
    border-right: 0.0625rem solid rgba(112, 112, 112, 0.5);
    border-left: 0.0625rem solid rgba(112, 112, 112, 0.5);
}

a {
    text-decoration: none;
}

Nav a:hover {
    color: var(--diviBlue);
}


div.sidebar-container {
    position: fixed;
    z-index: 1;
    width: 23.03125rem;
    height: 100%;
    margin-top: -4.6rem;
    overflow: hidden;
}


.sidebar {
    position: fixed;
    font: normal normal 500 1.125rem/0.625rem Guardians;
    text-align: left;
    color: var(--diviBlue);
    border: var(--diviSky);
    margin-top: 3.07rem;
    left: 5rem;
}

.profileDescription {
    font: normal normal 600 1.5rem/1.8125rem Lato;
    margin-left: -1rem;
    letter-spacing: 0px;
    color: var(--diviGrey);
    opacity: 1;
}

div.vertical {
    position: fixed;
    margin-top: -2rem;
    left: 25.0625rem;
    width: 0.0625rem;
    height: 90%;
    background: rgba(112, 112, 112, 0.5);
}

.sidebar hr {
    position: relative;
    width: 19.3125rem;
    margin-left: -1.875rem;
    opacity: 0.50;
}

.sidebar img {
    margin-top: 1.6875rem;
    top: 9rem;
    left: 3.625rem;
    width: 16rem;
    height: 14.9375rem;
}

#headerDivetus {
    color: var(--diviBlue);
    border: none;
}

.faIcons {
    margin-left: 1.29375rem;
    font-size: 3.09375rem;
}

.faIcons:hover {
    color: var(--diviDark);
}

.sidebarIcons {
    margin-left: -1rem;
}

.customTooltip {
    font-family: Lato !important;
}

.commissionStatusText {
    margin-left: 0.6rem;
    margin-top: 1.5rem;
    color: var(--diviGrey);
    text-align: left;
    font: normal normal normal 1.125rem/1.35rem Lato;
    letter-spacing: 1px;
    opacity: 1;
}

.commissionStatusState {
    font: normal normal bold 1.125rem/1.35rem Lato;
    color: var(--diviGreen);
}

.buttonStyle {
    background: var(--diviBlue) 0% 0% no-repeat padding-box;
    border: none;
    border-radius: 1.25rem;
    opacity: 1;
    margin-left: -1.7rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
    width: 19.25rem;
    height: 4.375rem;
    text-align: center;
    font: normal normal 600 1.35rem/1.63125rem Lato;
    letter-spacing: 0.45rem;
    color: var(--diviWhite);
    outline: none;
}

.buttonStyle:hover {
    background: var(--diviDark);
}

.carousel.carousel-slider .control-arrow {
    opacity: 1 !important;
}

.carousel .thumb.selected  {
    border: 1px solid var(--diviBlue) !important;
}

.thumb:hover {
    border: 1px solid var(--diviBlue) !important;
}

.carousel .thumb.selected {
    border: 1px solid var(--diviBlue) !important;
}

.carousel .thumb {
    margin-left: 30px !important;
}