.bodyContent {
    margin-left: 29.625em;
}

body {
    overflow-x: hidden;
}

.home {
    margin-top: 5.96875rem;
}

.home p.homeContent {
    color: var(--diviSilver);
    font: normal normal 500 1.35rem/1.85625rem Open Sans;
    max-width: 47.9375rem;
    max-height: 26.1875rem;
    text-align: left;
    display: block;
    opacity: 1;

}

.home p.homeContentRed {
    max-width: 47.9375rem;
    max-height: 9.0625rem;
    color: var(--diviRed);
    text-align: left;
    font: normal normal 800 1.35rem/1.63125rem Lato;
    letter-spacing: 0.075rem;
    display: block;
}

.home .homeTOS {
    color: var(--diviRed);
}

.tos {
    margin-top: 2.78125rem;
}

.dragon {
    position: absolute;
    top: 484px;
    left: 1240px;
    width: 630px;
    height: 446px;
}